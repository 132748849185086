module controllers {
    export module applicationcore {
        interface IAppNavScope extends ng.IScope {
            interval: any,
            LogoutUser(),
            GetNewToken(),
        }

        export class appNav {
            static $inject = ["$rootScope", '$scope', '$cookies', 'userAccountService', '$location', '$http', 'menuService',
                '$timeout', 'entityService', '$state', 'generalService', '$interval', 'tokenService'];

            public autoFocusContent: boolean;

            public entityList: Array<interfaces.applicationcore.IEntity>;

            private leftMenuState: boolean = false;
            private leftMenuSlide: string = "";
            private leftMenuItems: Array<interfaces.applicationcore.IMenu>;

            private rightMenuState: boolean = false;
            private rightMenuSlide: string = "";

            constructor(private $rootScope: interfaces.applicationcore.IRootScope, $scope: IAppNavScope, private $cookies: ng.cookies.ICookiesService,
                private userAccountService: interfaces.applicationcore.IUserAccountService, private $location: ng.ILocationService,
                private $http: ng.IHttpService, private menuService: interfaces.applicationcore.IMenuService, private $timeout: ng.ITimeoutService,
                private entityService: interfaces.applicationcore.IEntityService, private $state: ng.ui.IStateService, public generalService: interfaces.applicationcore.IGeneralService, $interval,
                private tokenService: interfaces.applicationcore.ITokenService
            ) {
                //Check if user is logged in
                if ($cookies.get("userToken") && $cookies.get("userFullName")) {
                    $rootScope.isLoggedIn = true;
                    $rootScope.hasBeenActive = false;
                    $rootScope.userFullName = $cookies.get("userFullName");
                    $rootScope.userId = $cookies.get("userId");
                    $rootScope.userToken = $cookies.get("userToken");
                    $http.defaults.headers.common["Authorization"] = "Bearer " + $rootScope.userToken;

                    if ($cookies.get("showMenu")) {

                        $rootScope.showMenu = ($cookies.get("showMenu") == "true");
                    }
                    else {
                        $rootScope.showMenu = true;
                    }

                    if ($cookies.get("sessionDoesNotExpire")) {
                        $rootScope.sessionDoesNotExpire = ($cookies.get("sessionDoesNotExpire") == "true");
                    }
                    else {
                        $rootScope.sessionDoesNotExpire = false;
                    }

                    if ($cookies.get("styleSheet")) {
                        var styleSheet = $cookies.get("styleSheet");
                        angular.element('head').append('<link href="templates/styles/' + styleSheet + '" rel="stylesheet" />');
                    }



                    this.loadEntitiesList();
                    this.loadCurrentEntity();
                } else {
                    $rootScope.isLoggedIn = false;
                    $rootScope.userFullName = undefined;
                    $rootScope.userToken = undefined;
                    $rootScope.userId = undefined;
                    $rootScope.showMenu = true;
                    $rootScope.sessionDoesNotExpire = false;
                    //$location.url("/login");
                }

                this.autoFocusContent = false;

                $rootScope.Messages = new Array<interfaces.applicationcore.IMessageItem>();

                //Function to check the expiry date every minute
                $scope.interval = setInterval(function () {
                    if ($rootScope.isLoggedIn) {
                        if ($rootScope.isLoggedIn === true) {
                            var token = $cookies.get("userToken");
                            if (token && $cookies.get("userFullName")) {
                                //Logged in. Checking expiry.
                                var tokenDate: moment.Moment = undefined;
                                var tokenIssuer: string = "";
                                //Get the exp date and username.
                                const splitStrings = token.split(".");
                                if (splitStrings.length > 0) {
                                    var decodedString = atob(splitStrings[1]);
                                    var part = angular.fromJson(decodedString);
                                    tokenDate = moment.unix(part.exp).utc();
                                    tokenIssuer = part.iss;
                                }

                                if (tokenDate && tokenIssuer == "GTS") {
                                    //5 minutes after getting the token.
                                    if (tokenDate.isBefore(moment().utc().add(55, 'minutes'))) {
                                        if ($rootScope.hasBeenActive == true) {
                                            if ($cookies.get("userToken") && $cookies.get("userFullName")) {
                                                $scope.GetNewToken();
                                            }
                                            return;
                                        }
                                        else {
                                            if ($rootScope.hasBeenActive == false) {
                                            }
                                            else {
                                                $rootScope.hasBeenActive = true;
                                                return;
                                            }
                                        }
                                    }

                                    if (tokenDate.isBefore(moment().utc().add(1, 'minutes'))) {
                                        if ($rootScope.sessionDoesNotExpire == false) {
                                            $scope.LogoutUser();
                                        }
                                        else {
                                            if ($cookies.get("userToken") && $cookies.get("userFullName")) {
                                                $scope.GetNewToken();
                                            }
                                        }
                                        return;
                                    }

                                    if (tokenDate.isBefore(moment().utc().add(1, 'minutes').add(59, 'seconds'))) {
                                        return;
                                    }

                                    if (tokenDate.isBefore(moment().utc().add(2, 'minutes'))) {
                                        if ($rootScope.sessionDoesNotExpire == false) {
                                            generalService.displayLogoutConfirmationBox("Your session is about to expire!", "Would you like to continue?").then(
                                                (result) => {
                                                    if (result) {
                                                        //Refresh token
                                                        if ($cookies.get("userToken") && $cookies.get("userFullName")) {
                                                            $scope.GetNewToken();
                                                        }
                                                        return;
                                                    }
                                                    else {
                                                        //First check if the token has been refreshed (another page)
                                                        var token2 = $cookies.get("userToken");

                                                        if (token2) {
                                                            var token2Date: moment.Moment = undefined;
                                                            //Get the exp date and username.
                                                            const splitStrings2 = token.split(".");
                                                            if (splitStrings2.length > 0) {
                                                                var decodedString2 = atob(splitStrings2[1]);
                                                                var part2 = angular.fromJson(decodedString2);
                                                                token2Date = moment.unix(part2.exp).utc();
                                                            }

                                                            if (token2Date) {
                                                                if (token2Date.isBefore(moment().utc().add(1, 'minutes'))) {
                                                                    $scope.LogoutUser();
                                                                }
                                                            }
                                                        }
                                                        return;
                                                    }
                                                }
                                            );
                                        }
                                        else {
                                            if ($cookies.get("userToken") && $cookies.get("userFullName")) {
                                                $scope.GetNewToken();
                                            }
                                        }
                                    }
                                    //If none of the above - do nothing.
                                }
                            }
                            else {
                                //No token - Go to loging page.
                                $rootScope.isLoggedIn = false;
                                $rootScope.userFullName = undefined;
                                $rootScope.userToken = undefined;
                                $rootScope.Entity = undefined;
                                this.entityList = [];

                                if ($http) {
                                    $http.defaults.headers.common["Authorization"] = undefined;
                                    delete $http.defaults.headers.common["Authorization"];
                                }

                                $state.go("login");
                                return;
                            }
                        }
                    }
                }, 60000);

                $scope.LogoutUser = () => {
                    this.logoutUser();
                }

                $scope.GetNewToken = () => {
                    return this.tokenService.getNewToken().get((result: any) => {
                        $rootScope.userToken = result.TokenString;
                        $rootScope.hasBeenActive = false;

                        $cookies.put("userFullName", $rootScope.userFullName);
                        //Set new token.
                        $cookies.put("userToken", result.TokenString);

                        $http.defaults.headers.common["Authorization"] = "Bearer " + $rootScope.userToken;
                    }, (errorResult) => {
                        generalService.displayMessageHandler(errorResult.data);
                        $scope.LogoutUser();
                    });
                }

                $rootScope.$watch('isLoggedIn', () => {
                    if ($rootScope.isLoggedIn) {
                        if ($rootScope.isLoggedIn === true) {
                            this.loadEntitiesList();
                            this.loadCurrentEntity();
                            this.loadMenu();
                        }
                    }
                });

                window.addEventListener('storage', (event) => {
                    if ($state.current.name && $state.current.name == "login")
                        return;

                    var tokenI: string = localStorage.getItem('entity');
                    if (tokenI && tokenI.length > 0) {
                        if ($rootScope.Entity) {
                            if ($rootScope.Entity.Id === +tokenI) {
                                return;
                            }
                        }
                        $state.reload();
                    }
                    else {
                        $state.go("login");
                    }
                }, false);
            }

            toggleLeftSideNav() {
                this.$timeout(() => {
                    this.leftMenuState = !this.leftMenuState;
                    if (this.leftMenuState) {
                        this.leftMenuSlide = "in-left";
                    } else {
                        this.leftMenuSlide = "out-left";
                    }
                });
            };

            toggleRightSideNav() {
                this.$timeout(() => {
                    this.rightMenuState = !this.rightMenuState;
                    if (this.rightMenuState) {
                        if (!this.entityList) {
                            this.loadEntitiesList();
                        }
                        this.rightMenuSlide = "in-right";
                    } else {
                        this.rightMenuSlide = "out-right";
                    }
                });
            };

            loadMenu() {
                this.menuService.getMenuList().query((data) => {
                    this.successMenuLoad(data)
                });
            };

            successMenuLoad = (data: Array<interfaces.applicationcore.IMenu>) => {
                this.leftMenuItems = data;
            }

            logoutClick($event: MouseEvent) {
                this.generalService.displayConfirmationBox("Logout?", 'Are you sure?').then(
                    (result) => {
                        if (result) {
                            this.logoutUser();
                        }
                    }
                );
            }

            logoutUser() {
                delete this.$http.defaults.headers.common["Authorization"];

                this.userAccountService.logoutUser().get({},
                    (data) => {
                        this.logoutSuccess(data);
                        localStorage.setItem('entity', "");
                    },
                    (data) => {
                        this.logoutFailure(data)
                    });
            }

            logoutSuccess(data) {
                this.$cookies.remove("userToken");
                this.$cookies.remove("userFullName");

                this.$rootScope.isLoggedIn = false;
                this.$rootScope.userFullName = undefined;
                this.$rootScope.userToken = undefined;
                this.$rootScope.Entity = undefined;
                this.entityList = [];

                if (this.$http) {
                    this.$http.defaults.headers.common["Authorization"] = undefined;
                    delete this.$http.defaults.headers.common["Authorization"];
                }

                this.generalService.displayMessageHandler(data);
                this.$state.go("login");
            }

            logoutFailure(errorResponse) {
                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
            }

            loadEntitiesList(): void {
                this.entityService.getUserEntities().query({ licensedOnly: true }, (success) => {
                    this.loadEntitiesSuccess(success);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                })
            }

            loadEntitiesSuccess(data): void {
                this.entityList = data;
            }

            entityChanged() {
                this.toggleRightSideNav();

                this.$rootScope.Entity = undefined;
                this.loadCurrentEntity();
                this.$state.reload();
            }

            failureEntityChange(failureData) {

            }

            loadCurrentEntity() {
                this.entityService.getCurrentEntity().query((successData) => {
                    this.successGetCurrentEntity(successData);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
            }

            successGetCurrentEntity(Entity: interfaces.applicationcore.IEntity) {
                var tokenO: string = localStorage.getItem('entity');
                localStorage.setItem('entity', Entity.Id.toString());
                this.$rootScope.Entity = Entity;
            }
        };
    }

    angular.module("app").controller("appNav", controllers.applicationcore.appNav);
}